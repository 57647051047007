import axios from 'axios'
import localforage from 'localforage'

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

api.interceptors.response.use(response => {
  response.data.token && localforage.setItem('access_token', response.data.token)
  return response
})

api.interceptors.request.use(async config => {
  const token = await localforage.getItem('access_token')
  if (token) config.headers.Authorization = `Bearer ${token}`

  return config
})

export default api
