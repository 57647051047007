import api from 'api/axios'
import { message } from 'antd';
import localforage from 'localforage'

export const loginApi = async (credentials) => {
  try {
    const res = await api.post('/login', credentials)

    return { data: res.data }
  } catch (error) {
    message.error(error.response?.data?.message)

    return { errors: error.response?.data?.errors }
  }
}

export const logoutApi = async () => {
  try {
    const res = await api.get('/logout')

    await localforage.clear();

    return { data: res.data }
  } catch (error) {
    message.error(error.response?.data?.message)

    return { errors: error.response?.data?.errors }
  }
}

export const changePasswordApi = async (id, values) => {
  try {
    const res = await api.post(`/change-password/${id}`, values)

    message.success('Password successfully changed')

    return { data: res.data }
  } catch (error) {
    message.error(error.response?.data?.message)

    return { errors: error.response?.data?.errors }
  }
}

export const isAuthApi = async () => {
  try {
    const res = await api.get('/isAuthenticated')

    return { data: res.data }
  } catch (error) {
    message.error(error.response?.data?.message)

    return { errors: error.response?.data?.errors }
  }
}
