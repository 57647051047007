import React, { Suspense, lazy, useEffect, useContext, useState } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import './App.less'
import PrivateRoute from 'router/PrivateRoute'
import { UserContext } from 'context/UserContext'
import { isAuthApi } from 'api/authApi'

const Admin = lazy(() => import('views/Admin'));
const Login = lazy(() => import('views/auth/Login'));

const App = () => {
  const [loaded, setLoaded] = useState(false)
  const [, setUser] = useContext(UserContext)

  useEffect(() => {
    const checkAuth = async () => {
      const res = await isAuthApi()

      if (res.data?.is_auth && res.data?.user) {
        setUser(res.data.user)
      }
      setLoaded(true)
    }
    checkAuth()
  }, [setUser])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Redirect exact from='/' to='/admin/dashboard' />
        <Redirect exact from='/admin' to='/admin/dashboard' />
        <PrivateRoute path="/admin" loaded={loaded}><Admin /></PrivateRoute>
        <Route path="/login"><Login /></Route>
      </Switch>
    </Suspense>
  );
}

export default App
