import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

const PrivateRoute = ({ children, loaded, ...rest }) => {
  const [user] = useContext(UserContext)
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !loaded ? (
          <h1>Loading</h1>
        ) : (
          Object.keys(user).length > 0
            ? children
            : <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
}

export default PrivateRoute
